const XHRRequest = new XMLHttpRequest();
const name = document.getElementById("name")
const phone = document.getElementById("phone")
const email = document.getElementById("email")
const message = document.getElementById("message")
const captcha = document.getElementById("g-recaptcha-response")
const validatorMessages = Array.from(document.getElementsByClassName('form__message'))


export function checkForm (token) {
    let validName, validPhone, validEmail, validCaptcha, validMessage = false

    function checkName () {
        let nextSibling = name.nextSibling
        while(nextSibling && nextSibling.nodeType != 1) {
            nextSibling = nextSibling.nextSibling
        }
        if (!name.value || name.value.length < 3) {
            validName = false
            name.classList.add('form__err')
            nextSibling.style.display = 'block'
        } else {
            validName = name.value
        }
    }
    checkName()

    function checkMessage () {
        let nextSibling = message.nextSibling
        while(nextSibling && nextSibling.nodeType != 1) {
            nextSibling = nextSibling.nextSibling
        }
        if (!message.value || name.value.length < 3) {
            validMessage = false
            message.classList.add('form__err')
            nextSibling.style.display = 'block'
        } else {
            validMessage = message.value
        }
    }
    checkMessage()

    function checkPhone () {
        let nextSibling = phone.nextSibling
        while(nextSibling && nextSibling.nodeType != 1) {
            nextSibling = nextSibling.nextSibling
        }
        if (!phone.value.match(/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/)) {
            validPhone = false
            phone.classList.add('form__err')
            nextSibling.style.display = 'block'
        } else {
            validPhone = phone.value
            validPhone = validPhone.replace(/[^0-9]/g, '')
            validPhone ='+7' + validPhone.substring(1, validPhone.length)
        }
    }
    checkPhone()

    function checkEmail () {
        let nextSibling = email.nextSibling
        while(nextSibling && nextSibling.nodeType != 1) {
            nextSibling = nextSibling.nextSibling
        }
        if (!email.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            validEmail = false
            email.classList.add('form__err')
            nextSibling.style.display = 'block'
        } else {
            validEmail = email.value

        }
    }
    checkEmail()

    function checkCaptcha () {
        const captchaStr = token
        if (!captchaStr) {
            validCaptcha = false
            captcha.classList.add('form__err')
            document.getElementById('message-captcha').style.display = 'block'
        } else {
            validCaptcha = captchaStr
            captcha.setAttribute('value', token)
        }
    }
    checkCaptcha()

    if (validName && validPhone && validEmail && validCaptcha && validMessage) {
        document.getElementById("submit").setAttribute('disabled', 'disabled')
        XHRRequest.open('POST', 'https://itomnia.ru/api/send-message', true)
        XHRRequest.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        XHRRequest.setRequestHeader('Content-Type', 'application/json')
        XHRRequest.send(JSON.stringify({
            name: validName,
            message: validMessage,
            phone: validPhone,
            email: validEmail,
            captcha: validCaptcha
        }))

        XHRRequest.onload = function() {
            if (XHRRequest.status != 200) { // HTTP ошибка?
                // обработаем ошибку
                new Notify({
                    status: 'error',
                    title: 'Произшла ошибка!',
                    text: 'Что-то пошло не так, повторите попытку через некоторое время',
                    effect: 'fade',
                    speed: 300,
                    customClass: null,
                    customIcon: null,
                    showCloseButton: true,
                    autoclose: true,
                    autotimeout: 3000,
                    gap: 20,
                    distance: 20,
                    type: 1,
                    position: 'right top'
                })
                document.getElementById("submit").removeAttribute('disabled')
                return;
            }
            name.value = ''
            phone.value = ''
            email.value = ''
            captcha.value = ''
            message.value = ''
            document.getElementById("submit").removeAttribute('disabled')
            new Notify({
                status: 'success',
                title: 'Сообщение успешно отправлено!',
                text: 'Спасибо за вашу заявку! В ближайшее время с вами свяжутся.',
                effect: 'fade',
                speed: 300,
                customClass: null,
                customIcon: null,
                showCloseButton: true,
                autoclose: true,
                autotimeout: 3000,
                gap: 20,
                distance: 20,
                type: 1,
                position: 'right top'
            })
        };

        XHRRequest.onerror = function() {
            // обработаем ошибку, не связанную с HTTP (например, нет соединения)
            document.getElementById("submit").removeAttribute('disabled')
            new Notify({
                status: 'error',
                title: 'Произшла ошибка!',
                text: 'Что-то пошло не так, повторите попытку через некоторое время',
                effect: 'fade',
                speed: 300,
                customClass: null,
                customIcon: null,
                showCloseButton: true,
                autoclose: true,
                autotimeout: 3000,
                gap: 20,
                distance: 20,
                type: 1,
                position: 'right top'
            })
        };
    }
}

Array.from(document.getElementsByTagName('input')).forEach(item => {
    item.addEventListener('change',() => {
        validatorMessages.forEach(el => {
            el.style.display = 'none'
            document.getElementById('message-captcha').style.display = 'none'
        })
        name.classList.remove('form__err')
        phone.classList.remove('form__err')
        email.classList.remove('form__err')
        captcha.classList.remove('form__err')
        message.classList.remove('form__err')
    })
})

// document.getElementById("submit").onclick = checkForm
